import { FC, ReactNode } from "react";

import { Text } from "components/ui";

interface HeaderProps {
  title?: string | ReactNode;
  titleCount?: string | number;
  actionButtons: () => ReactNode;
}

export const Header: FC<HeaderProps> = ({ title, titleCount, actionButtons }) => {
  if (!title) return null;

  return (
    <header className="sm:mt-4">
      <div className="mx-auto max-w-7xl px-4 pt-3 sm:px-6 sm:pt-4 lg:px-8">
        <div className="md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            <div className="flex space-x-2">
              <Text variant="h1" className="truncate">
                {title}
              </Text>
              {!!titleCount && (
                <Text variant="h1" className="!font-semibold">
                  ({titleCount})
                </Text>
              )}
            </div>
          </div>
          {actionButtons ? <div className="mt-3 md:mt-0">{actionButtons()}</div> : null}
        </div>
      </div>
    </header>
  );
};
