import { useRouter } from "next/router";

import * as Sentry from "@sentry/nextjs";
import { useEffect, useState } from "react";

import Layout from "components/layout/layout";
import { Button, Text } from "components/ui";
import { SkeletonText } from "components/ui/skeleton";

import { ERROR_TYPES } from "@/lib/errors";

type errorTypeType = keyof typeof ERROR_TYPES;
export default function ErrorPage({ statusCode }: { statusCode: number }) {
  const router = useRouter();
  const [errorType, setErrorType] = useState<errorTypeType>();

  useEffect(() => {
    if (router.query.errorType) {
      setErrorType(router.query.errorType as errorTypeType);
    } else {
      setErrorType("default_not_found");
    }

    if (router.query.errorPath) {
      Sentry.captureMessage(`Error page: ${router.query.errorType}`, {
        level: "info",
        extra: {
          errorType: router.query.errorType as string,
          errorPath: router.query.errorPath as string,
        },
      });
    }
  }, [router.query]);

  return (
    <Layout showFooter>
      <div className="grid h-[650px] grid-cols-3 place-content-center gap-4 sm:px-12">
        <div className="col-span-3 mb-14 text-center sm:col-span-2 sm:text-left">
          {errorType && ERROR_TYPES[errorType] ? (
            <>
              <Text variant="b2" className="font-bold">
                {statusCode || ERROR_TYPES[errorType].statusCode}
              </Text>
              <Text variant="h1" className="mb-2">
                Well, that's unfortunate.
              </Text>
              <Text variant="b2" className="mb-4">
                {ERROR_TYPES[errorType].message}
              </Text>
              {ERROR_TYPES[errorType].actionText && ERROR_TYPES[errorType].actionUrl && (
                <Button onClick={() => router.push(ERROR_TYPES[errorType].actionUrl)}>
                  {ERROR_TYPES[errorType].actionText}
                </Button>
              )}
            </>
          ) : (
            <>
              <SkeletonText className="mb-2 w-10" />
              <SkeletonText className="mb-2 h-12" />
              <SkeletonText className="mb-4" />
              <SkeletonText className="h-10 w-1/4" />
            </>
          )}
        </div>
      </div>
    </Layout>
  );
}
