import { useRouter } from "next/router";

import { FC, ReactNode, useEffect } from "react";
import { UserRole } from "types/userRole";

import { LOG_IN_URL } from "@/lib/constants";
import { useApp } from "@/lib/context/app-context";
import { ERROR_TYPES } from "@/lib/errors";

import Footer from "./footer";
import { Header } from "./header";
import Navbar from "./navbar";

interface LayoutProps {
  actionButtons?: () => JSX.Element;
  title?: string | ReactNode;
  titleCount?: string | number;
  noticeBar?: ReactNode;
  children?: React.ReactNode;
  requiredRoles?: UserRole[];
  protectedPage?: boolean;
  clientSideAuth?: boolean;
  unauthRedirect?: string;
  backgroundColor?: string;
  showFooter?: boolean;
  hideLinks?: boolean;
}

const Layout: FC<LayoutProps> = ({
  children,
  actionButtons,
  title,
  titleCount,
  noticeBar,
  requiredRoles = [],
  protectedPage = false,
  clientSideAuth = false,
  unauthRedirect,
  backgroundColor = "bg-zettlor-new-off-white",
  showFooter,
  hideLinks,
}) => {
  const { sessionStatus, user, session } = useApp();
  const router = useRouter();

  useEffect(() => {
    const runPageChecks = async () => {
      if (clientSideAuth && sessionStatus !== "loading") {
        // Check to see if the user is authenticated for protected pages
        if (!session && protectedPage) {
          const redirectQuery = `redirect=${router.asPath}`;
          router.push(`${LOG_IN_URL}?${redirectQuery}`);
        }

        // Check to make sure the user has access to the current page based on their role
        if (!!user) {
          const hasAccess = requiredRoles.length === 0 ? true : requiredRoles.find((rr) => user.selected_role == rr);
          if (!hasAccess) {
            router.push(unauthRedirect || `${ERROR_TYPES.no_access.url}&errorPath=${router.asPath}`);
          }
        }
      }
    };

    void runPageChecks();
  }, [router, requiredRoles, protectedPage, sessionStatus, clientSideAuth, user, session, unauthRedirect]);

  return (
    <div className={`min-h-full ${backgroundColor}`}>
      <Navbar hideLinks={hideLinks} />
      <main>
        {noticeBar && noticeBar}
        <Header title={title} titleCount={titleCount} actionButtons={actionButtons} />
        <div className="relative mx-auto min-h-[650px] max-w-7xl px-4 pb-20 pt-4 sm:px-6 sm:pt-8 lg:px-8">
          {children}
        </div>
      </main>
      {showFooter && <Footer />}
    </div>
  );
};

export default Layout;
