import classNames from "classnames";

type SkeletonBaseProps = {
  className?: string;
};

interface AvatarProps extends SkeletonBaseProps {
  // Limit this cause we don't use avatars bigger than this
  size?: "6" | "8" | "12" | "14";
}

interface SkeletonContainer {
  as?: keyof JSX.IntrinsicElements;
  children?: React.ReactNode;
  className?: string;
}

const SkeletonAvatar: React.FC<AvatarProps> = ({ size, className }) => {
  return (
    <div
      className={classNames(
        `mt-1 rounded-full bg-zettlor-new-black/[0.08] ltr:mr-2 rtl:ml-2 w-${size} h-${size}`,
        className,
      )}
    />
  );
};

const SkeletonText: React.FC<SkeletonBaseProps> = ({ className = "" }) => {
  return (
    <div
      className={classNames(
        `animate-pulse rounded-md bg-zettlor-new-black/[0.08] empty:before:inline-block empty:before:content-['']`,
        className,
      )}
    />
  );
};

const SkeletonButton: React.FC<SkeletonBaseProps> = ({ className }) => {
  return (
    <SkeletonContainer>
      <div className={classNames(`bg-zettlor-new-black/[0.08]`, className)} />
    </SkeletonContainer>
  );
};

const SkeletonTable: React.FC<SkeletonBaseProps> = () => {
  return (
    <SkeletonContainer>
      <div className="flex flex-col space-y-3">
        <SkeletonText className="h-12 w-full" />
        <SkeletonText className="h-10 w-full" />
        <SkeletonText className="h-10 w-full" />
        <SkeletonText className="h-10 w-full" />
        <SkeletonText className="h-10 w-full" />
      </div>
    </SkeletonContainer>
  );
};

const SkeletonSearch: React.FC<SkeletonBaseProps> = () => {
  return (
    <SkeletonContainer>
      <div className="flex flex-col space-y-6">
        <SkeletonText className="h-16 w-1/3" />
        <SkeletonText className="!mt-12 h-24 w-full" />
        <SkeletonText className="h-24 w-full" />
        <SkeletonText className="h-24 w-full" />
        <SkeletonText className="h-24 w-full" />
      </div>
    </SkeletonContainer>
  );
};

const SkeletonDisplay: React.FC<SkeletonBaseProps> = () => {
  return (
    <SkeletonContainer>
      <div className="flex flex-col space-y-3">
        <SkeletonText className="mt-10 h-12 w-1/4" />
        <SkeletonText className="h-8 w-2/5" />
        <SkeletonText className="!mt-8 h-1 w-full" />
        <div className="!mt-10 flex gap-10">
          <div className="w-2/3 space-y-4">
            <SkeletonText className="h-8 w-full" />
            <SkeletonText className="h-8 w-4/5" />
            <SkeletonText className="h-8 w-3/4" />
            <SkeletonText className="h-8 w-4/5" />
            <SkeletonText className="h-8 w-full" />
            <SkeletonText className="h-8 w-3/5" />
            <SkeletonText className="h-8 w-4/5" />
            <SkeletonText className="h-8 w-4/5" />
            <SkeletonText className="h-8 w-full" />
          </div>
          <div className="w-1/3 space-y-8">
            <SkeletonText className="h-40 w-full" />
            <SkeletonText className="h-56 w-full" />
          </div>
        </div>
      </div>
    </SkeletonContainer>
  );
};

const SkeletonDialog: React.FC<SkeletonBaseProps> = () => {
  return (
    <SkeletonContainer>
      <div className="flex flex-col space-y-4">
        <SkeletonText className="h-4 w-1/3" />
        <SkeletonText className="!mt-3 h-10 w-1/2" />
        <SkeletonText className="h-1 w-full" />
        <div className="flex space-x-4">
          <SkeletonText className="h-8 w-[75px]" />
          <SkeletonText className="h-8 w-1/3" />
        </div>
      </div>
    </SkeletonContainer>
  );
};

const SkeletonSlideout: React.FC<SkeletonBaseProps> = () => {
  return (
    <SkeletonContainer>
      <div className="flex flex-col space-y-3">
        <SkeletonText className="mt-4 h-12 w-2/4" />
        <SkeletonText className="h-8 w-3/5" />
        <SkeletonText className="!my-6 h-1 w-full" />
        <div className="space-y-4">
          <SkeletonText className="h-40 w-full" />
          <SkeletonText className="h-8 w-4/5" />
          <SkeletonText className="h-8 w-3/4" />
          <SkeletonText className="h-8 w-4/5" />
          <SkeletonText className="h-8 w-full" />
        </div>
      </div>
    </SkeletonContainer>
  );
};

const SkeletonContainer: React.FC<SkeletonContainer> = ({ children, as, className }) => {
  const Component = as || "div";
  return <Component className={classNames("w-full animate-pulse", className)}>{children}</Component>;
};

export {
  SkeletonContainer,
  SkeletonAvatar,
  SkeletonText,
  SkeletonButton,
  SkeletonTable,
  SkeletonSearch,
  SkeletonDisplay,
  SkeletonDialog,
  SkeletonSlideout,
};
